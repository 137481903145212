.app-toolbar {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px 0 0;
  gap: 8px;
}
.app-toolbar img {
  height: 100%;
}
.app-toolbar .app-avatar {
  border-radius: 50%;
  padding: 4px 0;
}
.app-toolbar .app-name {
  font-weight: bold;
  color: #00468c;
}
