
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.app-toolbar {
  height: @eva-header;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 @eva-padding 0 0;
  gap: @eva-padding;
  img {
    height: 100%;
  }
  .app-avatar {
    border-radius: 50%;
    padding: (@eva-padding / 2) 0;
  }
  .app-name {
    font-weight: bold;
    color: #00468c;
  }
}
